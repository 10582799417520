@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
:root {
  height: 100%;
  scroll-behavior: smooth;
}

* {
  scroll-behavior: smooth;
}

button {
  outline: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  overflow-x: hidden;
  background: white;
}

@font-face {
  font-family: "FightNight";
  src: url("/fonts/Fight Night.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sansation";
  src: url("/fonts/Sansation_Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sansation";
  src: url("/fonts/Sansation_Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SandeMoreDemo";
  src: url("/fonts/SandeMore-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SandeMoreDemo";
  src: url("/fonts/SandeMore-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SandeMoreDemo";
  src: url("/fonts/SandeMore-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "SandeMoreDemo";
  src: url("/fonts/SandeMore-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "SandeMoreDemo";
  src: url("/fonts/SandeMore-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sansation Bold";
  src: url("/fonts/Sansation_Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Original";
  src: url("/fonts/Original-Black.otf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Original";
  src: url("/fonts/Original-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Original";
  src: url("/fonts/Original-Light.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Original";
  src: url("/fonts/Original-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Original";
  src: url("/fonts/Original-Thin.otf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* @font-face {
  font-family: "Calgary";
  src: url("/fonts/Calgary ttf.ttf") format("truetype");
} */

@font-face {
  font-family: "Calgary";
  src: url("/fonts/Calgary.otf") format("opentype");
}

.property-page-hero-container {
  overflow-y: scroll; /* or auto */
}

.property-page-hero-container::-webkit-scrollbar {
  display: none;
}

.related-properties-container {
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.related-properties-container::-webkit-scrollbar {
  display: none;
}

.scrollAnimation {
  animation: scrollY 280s linear infinite;
}

.heroScrollAnimation {
  animation: scrollYHero 7s linear;
  animation-fill-mode: forwards;
}

.image-section-animation a,
.image-section-animation span {
  opacity: 0;
  transition: opacity 500ms ease;
}

.image-section-animation:hover a,
.image-section-animation:hover span {
  opacity: 1;
}

.image-section_displayed_over {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: background-color 350ms ease;
  background-color: transparent;
  padding: 20px 20px 0 20px;
}

.image-section_displayed_over:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.faq-chevron-down-icon {
  transition: transform 200ms ease-in;
}

.faq-chevron-down-icon-rotate {
  transform: rotate(-180deg) !important;
}

@keyframes scrollY {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-90%);
  }
}

@keyframes scrollYHero {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1.5%);
  }
  75% {
    transform: translateY(1.5%);
  }
  100% {
    transform: translateY(1.5%);
  }
}

@keyframes scrollXHero {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1.5%);
  }
  75% {
    transform: translateX(1.5%);
  }
  100% {
    transform: translateX(1.5%);
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.photos_modal_image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: background-color 350ms ease;
  background-color: transparent;
  padding: 20px 20px 0 20px;
}

.photos_modal_image:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.margin-t-80px {
  margin-top: 80px;
}

.margin-t-40px {
  margin-top: 40px;
}

.margin-t-120px {
  margin-top: 120px;
}

@media screen and (max-width: 475px) {
  .heroScrollAnimation {
    /* animation: scrollXHero 7s linear; */
    /* animation-fill-mode: forwards; */
    animation: none;
  }

  .mobile-mt-60px {
    margin-top: 60px !important;
  }

  .mobile-mt-20px {
    margin-top: 20px !important;
  }
}

.infinite-rotate {
  animation: infinite buttonRotate 2s linear;
}

@keyframes buttonRotate {
  0% {
    transform: rotate(30deg);
  }
  5% {
    transform: rotate(60deg);
  }
  10% {
    transform: rotate(90deg);
  }
  15% {
    transform: rotate(120deg);
  }
  20% {
    transform: rotate(150deg);
  }
  25% {
    transform: rotate(180deg);
  }
  30% {
    transform: rotate(210deg);
  }
  35% {
    transform: rotate(240deg);
  }
  40% {
    transform: rotate(270deg);
  }
  45% {
    transform: rotate(300deg);
  }
  50% {
    transform: rotate(330deg);
  }
  55% {
    transform: rotate(360deg);
  }
  60% {
    transform: rotate(390deg);
  }
  65% {
    transform: rotate(420deg);
  }
  70% {
    transform: rotate(450deg);
  }
  75% {
    transform: rotate(480deg);
  }
  80% {
    transform: rotate(510deg);
  }
  85% {
    transform: rotate(540deg);
  }
  90% {
    transform: rotate(570deg);
  }
  95% {
    transform: rotate(600deg);
  }
  100% {
    transform: rotate(630deg);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
